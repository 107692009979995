import React from "react"

import Layout from "../components/layout"

const socials = [
  {
    label: "GitHub",
    displayHref: "https://github.com/hgb123",
    realHref: "https://github.com/hgb123",
  },
  {
    label: "StackOverflow",
    displayHref: "https://stackoverflow.com/users/6655160/hgb123",
    realHref: "https://stackoverflow.com/users/6655160/hgb123",
  },
  {
    label: "LinkedIn",
    displayHref: "https://www.linkedin.com/in/telake/",
    realHref: "https://www.linkedin.com/in/telake/",
  },
  {
    label: "Toptal",
    displayHref: "https://www.toptal.com/resume/bao-gia-ho",
    realHref: "https://topt.al/5mcdy4",
  },
]

const ContactPage = () => {
  const handleLinkClick = href => event => {
    event.preventDefault()
    href && window.open(href)
  }

  return (
    <Layout title="Contact | Bao Ho">
      <p className="text-3xl font-semibold">Social links</p>
      <div className="space-y-2 mt-10">
        {socials.map((social, i) => (
          <div className="space-x-2" key={i}>
            <a
              href={social.displayHref || "/#"}
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 font-medium"
              onClick={handleLinkClick(social.realHref)}
            >
              {social.label}
            </a>
            {social.displayHref ? null : <span>(almost done)</span>}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ContactPage
